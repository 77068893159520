import { jsonp, ajax, restAjax, loadScript, cookie } from '@ifeng-fe/ui_base';
import { IEVersion } from '../utils';

// export const USERAPI = '';
// eslint-disable-next-line no-undef
export const USERAPI = InterfaceDomain;
// eslint-disable-next-line no-undef
export const LITTLEFILEAPI = LittleFileDomain;

// console.log(InterfaceDomain);

// export const USERAPI = process.CONFIG.InterfaceDomain;

console.log(IEVersion());

let isJsonp = true;

if (IEVersion() > 10) {
    isJsonp = false;
}

const request = async (url, params, runJsonp) => {
    // eslint-disable-next-line init-declarations
    let res;

    try {
        if (runJsonp) {
            res = await jsonp(url, { ...params });
        } else {
            res = await restAjax(url, { ...params });
        }

        return res;
    } catch (e) {
        const { code } = e;

        if (code === 'NotLogin') {
            window.location.href = `${USERAPI}/login`;
        } else {
            throw e;
        }
    }
};

/**
 * 获取图片验证码
 * @param {Number} type 图片验证码的类型 0 图片 1 文字
 */
const getVerificationCode = async (type = 2) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/get/captcha`, {
                data: { type, platform: 'w', systemid: 1 },
                cache: new Date().getTime(), // 取消ie自动缓存
                jsonpCallback: 'getVerificationCode',
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/get/captcha`, {
                type: 'get',
                query: { type, platform: 'w', systemid: 1 },
                cache: new Date().getTime(), // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { tips } = e;

        console.error(e);
        alert(tips);
    }
};

/**
 * 发送短信验证码
 * @param {Object} params mobile 手机号 smsform 短信形式 captcha_id 图片验证码id captcha_code 图片验证码
 * @param {Function} showImgCode 显示图片验证码的回调
 * 发送短信的次数超过指定次数后，会返回错误码CaptchaRequired，收到该错误码后需要调用获取图片验证码接口显示图片验证码，下次发送短信时需要把图片验证码通过captcha_id和captcha_code两个参数传递过来。
 */
const sendSms = async (params, showImgCode, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/sendsms`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'sendSms',
                // body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            console.log(res);

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/sendsms`, {
                type: 'post',
                query: {
                    platform: 'w',
                    systemid: 1,
                },
                body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        console.error(e);
        if (code === 'CaptchaRequired') {
            showImgCode();
        } else if (failCallback) {
            failCallback(code, tips);
        } else {
            alert(tips);
        }
    }
};

/**
 * 找回密码时，发送短信验证码
 * @param {Object} params mobile 手机号 smsform 短信形式 captcha_id 图片验证码id captcha_code 图片验证码
 * @param {Function} showImgCode 显示图片验证码的回调
 * 发送短信的次数超过指定次数后，会返回错误码CaptchaRequired，收到该错误码后需要调用获取图片验证码接口显示图片验证码，下次发送短信时需要把图片验证码通过captcha_id和captcha_code两个参数传递过来。
 */
const sendsmsbyaccount = async (params, showImgCode, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/sendsmsbyaccount`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'sendsmsbyaccount',
                // body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            console.log(res);

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/sendsmsbyaccount`, {
                type: 'post',
                query: {
                    platform: 'w',
                    systemid: 1,
                },
                body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        console.error(e);
        if (code === 'CaptchaRequired') {
            showImgCode();
        } else if (failCallback) {
            failCallback(code, tips);
        } else {
            alert(tips);
        }
    }
};

/**
 * 手机号注册
 * @param {Object} params mobile 手机号 smscode 短信验证码 password 明文密码
 */
const registerPost = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/register/mobilesms`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'loginPost',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/register/mobilesms`, {
                type: 'post',
                query: {
                    platform: 'w',
                    systemid: 1,
                },
                body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
        // alert(tips);
    }
};

/**
 * 登录请求
 * @param {Number} type 登录类型 0 手机号 1 账密 2 ioauth(授权)
 * @param {Object} params 请求参数
 */
const loginPost = async (type, params, failCallback) => {
    try {
        const postUrls = [
            `${USERAPI}/api/v1/login/mobilesms`,
            `${USERAPI}/api/v1/login/common`,
            `${USERAPI}/api/v1/ioauth/login/common`,
        ];

        if (isJsonp) {
            const res = await jsonp(postUrls[type], {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'loginPost',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(postUrls[type], {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                cache: false,
                corsCookie: true,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
        // alert(tips);
    }
};

/**
 * 获取用户信息
 */
const cacheUserInfo = async () => {
    try {
        if (isJsonp) {
            console.log('jsonp');
            const res = await jsonp(`${USERAPI}/api/v1/get/userinfobytoken`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                },
                jsonpCallback: 'getUserInfo',
                cache: false, // 取消ie自动缓存
            });

            if (res) {
                const { realname_mobile, nickname, username } = res;

                if (username || nickname) {
                    cookie.set('IF_USER', nickname || username || '凤凰网友', '/', '.ifeng.com');
                }

                if (realname_mobile) {
                    cookie.set('IF_REAL', '0', '/', '.ifeng.com');
                } else {
                    cookie.set('IF_REAL', '1', '/', '.ifeng.com');
                }
            }

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/get/userinfobytoken`, {
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                cache: false,
                corsCookie: true,
            });

            if (res) {
                const { realname_mobile, nickname, username } = res;

                if (username || nickname) {
                    cookie.set('IF_USER', nickname || username || '凤凰网友', '/', '.ifeng.com');
                }

                if (realname_mobile) {
                    cookie.set('IF_REAL', '0', '/', '.ifeng.com');
                } else {
                    cookie.set('IF_REAL', '1', '/', '.ifeng.com');
                }
            }

            return res;
        }
    } catch (e) {
        console.error(e);

        throw e;
    }
};

/**
 * 校验短信验证码 绑定手机号时使用
 * @param {Object} params { mobile手机号, smscode短信验证码 }
 * @param {*} failCallback 失败的回调
 */
const checkSms = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/check/mobilesms`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    ...params,
                },
                jsonpCallback: 'checkSms',
                cache: false,
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/check/mobilesms`, {
                type: 'get',
                query: {
                    platform: 'w',
                    systemid: 1,
                    ...params,
                },
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 绑定实名手机号
 * @param {Objetc} params mobile sms auth_token
 * @param {*} failCallback 失败的回调
 */
const bindRealNameMobile = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/bind/realnamemobile`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'bindRealNameMobile',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/bind/realnamemobile`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 绑定手机号
 * @param {Objetc} params mobile sms auth_token
 * @param {*} failCallback 失败的回调
 */
const bindMobile = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/bind/mobile`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'bindMobile',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/bind/mobile`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 检查手机号是否存在
 * @param {*} params
 * @param {*} failCallback
 */
const checkMobile = async (mobile, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/check/mobile`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    mobile,
                },
                jsonpCallback: 'checkMobile',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/check/mobile`, {
                type: 'get',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                    mobile,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        if (failCallback) {
            failCallback(code, tips);
        }
        console.error(e);
    }
};

/**
 * 换绑手机号
 * @param {*} params 参数
 * @param {*} failCallback 失败回调
 */
const resetMobile = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/rebind/mobile`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'resetMobile',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/rebind/mobile`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 退出登录
 */
const logoutPost = async () => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/logout`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                },
                jsonpCallback: 'logout',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/logout`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        console.error(tips);
    }
};

/**
 * 验证邮箱
 * @param {*} failCallback 失败回调
 */
const checkmailbytoken = async failCallback => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/sendmail/checkmailbytoken`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    // params: JSON.stringify(params),
                },
                jsonpCallback: 'checkmailbytoken',
                // body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            console.log(res);

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/sendmail/checkmailbytoken`, {
                type: 'get',
                query: {
                    platform: 'w',
                    systemid: 1,
                },
                // body: {},
                corsCookie: true,
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        console.error(e);

        if (failCallback) {
            failCallback(code, tips);
        }
        // alert(tips);
    }
};

/**
 * 发送绑定邮箱验证
 * @param {*} params
 * @param {*} failCallback
 */
const sendmail = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/sendmail/bindmailbytoken`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'sendmail',
                // body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            console.log(res);

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/sendmail/bindmailbytoken`, {
                type: 'post',
                query: {
                    platform: 'w',
                    systemid: 1,
                },
                body: { ...params },
                corsCookie: true,
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        console.error(e);

        if (failCallback) {
            failCallback(code, tips);
        }
        // alert(tips);
    }
};

/**
 * 绑定邮箱
 * @param {*} params
 * @param {*} failCallback
 */
const bindEmailPost = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/bind/mail`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'bindEmailPost',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/bind/mail`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 发送换绑邮箱验证
 * @param {*} params
 * @param {*} failCallback
 */
const sendResetmail = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/sendmail/resetmailbytoken`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'sendResetmail',
                // body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            console.log(res);

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/sendmail/resetmailbytoken`, {
                type: 'post',
                query: {
                    platform: 'w',
                    systemid: 1,
                },
                body: { ...params },
                corsCookie: true,
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        console.error(e);

        if (failCallback) {
            failCallback(code, tips);
        }
        // alert(tips);
    }
};

/**
 * 设置初始密码
 * @param {*} params
 * @param {*} failCallback
 */
const setInitialPwd = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/pwd/set/fastregistration`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'fastregistration',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/pwd/set/fastregistration`, {
                type: 'post',
                query: {
                    platform: 'c', // web
                    systemid: 7, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 重置密码
 * @param {*} params
 * @param {*} failCallback
 */
const setNewPwd = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/pwd/reset/old`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'setNewPwd',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/pwd/reset/old`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 检查邮箱验证码 获取authtoken
 * @param {*} params
 * @param {*} failCallback
 */
const checkMailcode = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/check/mailcode`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'checkMailcode',
                // body: { ...params },
                cache: false, // 取消ie自动缓存
            });

            console.log(res);

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/check/mailcode`, {
                type: 'get',
                query: {
                    platform: 'w',
                    systemid: 1,
                    ...params,
                },
                corsCookie: true,
                cache: false, // 取消ie自动缓存
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        console.error(e);

        if (failCallback) {
            failCallback(code, tips);
        }
        // alert(tips);
    }
};

/**
 * 换绑邮箱
 * @param {*} params
 * @param {*} failCallback
 */
const rebindEmailPost = async (params, failCallback) => {
    try {
        if (isJsonp) {
            const res = await jsonp(`${USERAPI}/api/v1/rebind/mail`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'rebindEmailPost',
                cache: false, // 取消ie自动缓存
            });

            return res;
        } else {
            const res = await restAjax(`${USERAPI}/api/v1/rebind/mail`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            return res;
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
        console.error(e);
    }
};

/**
 * 设置昵称
 * @param {*} params
 * @param {*} failCallback
 */
const setNickname = async (params, failCallback) => {
    try {
        if (isJsonp) {
            await jsonp(`${USERAPI}/api/v1/set/nickname`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'setNickname',
                cache: false, // 取消ie自动缓存
            });

            alert('设置成功');
        } else {
            await restAjax(`${USERAPI}/api/v1/set/nickname`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            alert('设置成功');
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
    }
};

/**
 * 设置头像
 * @param {*} params
 * @param {*} failCallback
 */
const setAvatar = async (params, successCallback, failCallback) => {
    try {
        if (isJsonp) {
            await jsonp(`${USERAPI}/api/v1/set/avatar`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    params: JSON.stringify(params),
                },
                jsonpCallback: 'setavatar',
                cache: false, // 取消ie自动缓存
            });

            successCallback();
        } else {
            await restAjax(`${USERAPI}/api/v1/set/avatar`, {
                type: 'post',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                body: {
                    ...params,
                },
                corsCookie: true,
                cache: false,
            });

            successCallback();
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback(code, tips);
    }
};

/**
 * 获取一次性上传token
 * @param {Function} errorCallback
 */
const getUploadToken = async errorCallback => {
    try {
        if (isJsonp) {
            return await jsonp(`${USERAPI}/api/v1/avatartoken/get`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                },
                jsonpCallback: 'getuploadtoken',
                cache: false, // 取消ie自动缓存
            });
        } else {
            return await restAjax(`${USERAPI}/api/v1/avatartoken/get`, {
                type: 'get',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                },
                corsCookie: true,
                cache: false,
            });
        }
    } catch (e) {
        const { code, tips } = e;

        errorCallback(code, tips);
    }
};

/**
 * ioauth查询三方网站信息
 * @param {*} client_id
 * @param {*} redirect_url 第三方接入时分配的client_id
 * @param {*} errorCallback 第三方插入时设定的回调地址
 */
const getThirdsystem = async (client_id, redirect_url, errorCallback) => {
    try {
        if (isJsonp) {
            return await jsonp(`${USERAPI}/api/v1/ioauth/get/thirdsystem`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    client_id,
                    redirect_url,
                },
                jsonpCallback: 'thirdsystem',
                cache: false, // 取消ie自动缓存
            });
        } else {
            return await restAjax(`${USERAPI}/api/v1/ioauth/get/thirdsystem`, {
                type: 'get',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                    client_id,
                    redirect_url,
                },
                corsCookie: true,
                cache: false,
            });
        }
    } catch (e) {
        const { code, tips } = e;

        errorCallback(code, tips);
    }
};

/**
 * 查询用户联系方式
 * @param {*} account 用户的帐号
 * @param {*} captcha_id 图片验证码ID
 * @param {*} captcha_code 图片验证码
 * @param {*} errorCallback 失败的回调
 */
const contactsbyaccount = async (account, captcha_id, captcha_code, errorCallback) => {
    try {
        if (isJsonp) {
            return await jsonp(`${USERAPI}/api/v1/get/contactsbyaccount/desensitized`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    account,
                    captcha_id,
                    captcha_code,
                },
                jsonpCallback: 'contactsbyaccount',
                cache: false, // 取消ie自动缓存
            });
        } else {
            return await restAjax(`${USERAPI}/api/v1/get/contactsbyaccount/desensitized`, {
                type: 'get',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                    account,
                    captcha_id,
                    captcha_code,
                },
                corsCookie: true,
                cache: false,
            });
        }
    } catch (e) {
        const { code, tips } = e;

        errorCallback(code, tips);
    }
};

/**
 * 手机找回密码
 * @param {*} params
 */
const findpwd = async params => {
    if (isJsonp) {
        const res = await jsonp(`${USERAPI}/api/v1/pwd/findpwd`, {
            data: {
                platform: 'w',
                systemid: 1,
                params: JSON.stringify(params),
            },
            jsonpCallback: 'findpwd',
            cache: false, // 取消ie自动缓存
        });

        return res;
    } else {
        const res = await restAjax(`${USERAPI}/api/v1/pwd/findpwd`, {
            type: 'post',
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            body: {
                ...params,
            },
            corsCookie: true,
            cache: false,
        });

        return res;
    }
};

/**
 * 发送邮件--重置密码
 * @param {*} params
 */
const resetPwdSendMail = async params => {
    if (isJsonp) {
        const res = await jsonp(`${USERAPI}/api/v1/sendmail/resetpwd`, {
            data: {
                platform: 'w',
                systemid: 1,
                params: JSON.stringify(params),
            },
            jsonpCallback: 'findpwd',
            cache: false, // 取消ie自动缓存
        });

        return res;
    } else {
        const res = await restAjax(`${USERAPI}/api/v1/sendmail/resetpwd`, {
            type: 'post',
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            body: {
                ...params,
            },
            corsCookie: true,
            cache: false,
        });

        return res;
    }
};

/**
 * 邮件重置密码
 * @param {*} params
 */
const resetPwdByMail = async params => {
    if (isJsonp) {
        const res = await jsonp(`${USERAPI}/api/v1/pwd/reset/mail`, {
            data: {
                platform: 'w',
                systemid: 1,
                params: JSON.stringify(params),
            },
            jsonpCallback: 'findpwd',
            cache: false, // 取消ie自动缓存
        });

        return res;
    } else {
        const res = await restAjax(`${USERAPI}/api/v1/pwd/reset/mail`, {
            type: 'post',
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            body: {
                ...params,
            },
            corsCookie: true,
            cache: false,
        });

        return res;
    }
};

/**
 * ioauth获取code
 * @param {*} client_id 第三方接入时分配的client_id
 * @param {*} errorCallback 失败的回调
 */
const getIoauthCode = async client_id => {
    try {
        if (isJsonp) {
            return await jsonp(`${USERAPI}/api/v1/ioauth/code/get`, {
                data: {
                    platform: 'w',
                    systemid: 1,
                    client_id,
                },
                jsonpCallback: 'getIoauthCode',
                cache: false, // 取消ie自动缓存
            });
        } else {
            return await restAjax(`${USERAPI}/api/v1/ioauth/code/get`, {
                type: 'get',
                query: {
                    platform: 'w', // web
                    systemid: 1, // 凤凰通
                    client_id,
                },
                corsCookie: true,
                cache: false,
            });
        }
    } catch (e) {
        throw e;
    }
};

export {
    getVerificationCode,
    sendSms,
    sendsmsbyaccount,
    registerPost,
    loginPost,
    cacheUserInfo,
    checkSms,
    bindRealNameMobile,
    bindMobile,
    checkMobile,
    resetMobile,
    logoutPost,
    checkmailbytoken,
    sendmail,
    bindEmailPost,
    sendResetmail,
    setInitialPwd,
    setNewPwd,
    checkMailcode,
    rebindEmailPost,
    setNickname,
    setAvatar,
    getUploadToken,
    getThirdsystem,
    contactsbyaccount,
    findpwd,
    resetPwdSendMail,
    resetPwdByMail,
    getIoauthCode,
};
