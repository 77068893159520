import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

import Nav from '../../../components/userSecurityNav';
import Content from './content';
import Footer from '../../../components/userSecurityFooter';

class Layout extends React.PureComponent {
    static propTypes = {
        // content: PropTypes.object,
    };

    state = {};

    render() {
        return (
            <div className={styles.layout}>
                <Nav cur={1} />
                <Content />
                <Footer />
            </div>
        );
    }
}

export default errorBoundary(Layout);
