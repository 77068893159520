import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

import { cacheUserInfo } from '../../../../services/api';
import { cookie } from '@ifeng-fe/ui_base';

class Content extends React.PureComponent {
    static propTypes = {
        // content: PropTypes.object,
    };

    state = {
        userInfo: {},
        anquan_level: 0,
    };

    componentDidMount = () => {
        this.initInfo();
    };

    initInfo = async () => {
        try {
            const userInfo = await cacheUserInfo();

            console.log(userInfo);
            if (userInfo) {
                const { mobile, mail } = userInfo;

                this.setState({
                    userInfo,
                });

                if (mobile && mail) {
                    this.setState({
                        anquan_level: 100,
                    });
                } else if (mobile || mail) {
                    this.setState({
                        anquan_level: 60,
                    });
                } else {
                    this.setState({
                        anquan_level: 30,
                    });
                }
            }
        } catch (error) {
            if (error.code === 'NotLogin') {
                cookie.del('sid', '/', '.ifeng.com');
                cookie.del('IF_USER', '/', '.ifeng.com');
                cookie.del('IF_REAL', '/', '.ifeng.com');
                window.location.href = './login';
            }
        }
    };

    formatPhoneNum = mobile => {
        const arr = mobile.split('');

        arr.splice(3, 4, '****');

        return arr.join('');
    };

    formatMail = mail => {
        if (!mail) {
            return mail;
        }
        const arr = mail.split('@');

        let first = arr[0].split('');
        const second = arr[1].split('.');

        first = first.map((item, index) => {
            return index <= 1 ? item : '*';
        });

        const domain = second[0].split('').map((item, index) => {
            return index === second[0].split('').length - 1 ? item : '*';
        });

        return `${first.join('')}@${domain.join('')}.${second[1]}`;
    };

    render() {
        const {
            userInfo,
            userInfo: { user_status },
            anquan_level,
        } = this.state;

        // if (userInfo.mail) console.log(this.formatMail(userInfo.mail));

        const renderAnquan_level = () => {
            let res = {};

            switch (anquan_level) {
                case 30:
                    res = {
                        style: styles.level_bar_low,
                        txt: '低',
                        txtColor: '#e20000',
                    };
                    break;
                case 60:
                    res = {
                        style: styles.level_bar_mid,
                        txt: '中',
                        txtColor: '#fca534',
                    };
                    break;
                case 100:
                    res = {
                        style: styles.level_bar_high,
                        txt: '高',
                        txtColor: '#56B635',
                    };
                    break;

                default:
                    break;
            }

            return res;
        };

        const mobilePath = userInfo.mobile ? './security/changeMobile' : './security/bindMobile';
        const mallPath = userInfo.mail ? './security/changeEmail' : './security/bindEmail';

        const lvBar = (
            <div className={styles.security_lv}>
                <div className={styles.slv_inner}>
                    <span className={styles.i_icon_lock} />
                    <span className={styles.txt}>账号安全级别:</span>
                    <div className={styles.level_box}>
                        <div className={renderAnquan_level().style} style={{ width: `${anquan_level}%` }} />
                    </div>
                    <span className={styles.level_txt} style={{ color: renderAnquan_level().txtColor }}>
                        {renderAnquan_level().txt}
                    </span>
                </div>
                <p className={styles.tips}>提示：您的账号存在安全风险，建议您通过完善以下信息提高安全级别！</p>
            </div>
        );

        const Items = (
            <Fragment>
                <div className={styles.item}>
                    <div className={styles.item_inner}>
                        <a className={styles.rightTxt} href={mobilePath}>
                            {userInfo.mobile ? '立即修改' : '立即绑定'}
                        </a>
                        <div className={styles.left_box}>
                            <span className={styles.phoneIcon} />
                            <span className={styles.fz16}>绑定手机</span>
                            <span>
                                {userInfo.mobile
                                    ? `：当前手机是${this.formatPhoneNum(userInfo.mobile)}`
                                    : '：您尚未绑定手机'}
                            </span>
                            {userInfo.mobile ? <span className={styles.check} /> : null}
                            {userInfo.mobile ? null : (
                                <p className={styles.itemTip}>绑定之后，您可以使用手机登录凤凰网或找回密码</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.item_inner}>
                        <a className={styles.rightTxt} href={mallPath}>
                            {userInfo.mail ? '立即修改' : '立即绑定'}
                        </a>
                        <div className={styles.left_box}>
                            <span className={styles.mailIcon} />
                            <span className={styles.fz16}>绑定邮箱</span>
                            <span>
                                {userInfo.mail ? `：当前邮箱是${this.formatMail(userInfo.mail)}` : '：您尚未绑定邮箱'}
                            </span>
                            {userInfo.mail ? <span className={styles.check} /> : null}
                            {userInfo.mail ? null : (
                                <p className={styles.itemTip}>绑定之后，您可以使用邮箱登录凤凰网或找回密码</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.item_inner}>
                        <a className={styles.rightTxt} href="./security/changePass">
                            {Number(user_status) === 0 || Number(user_status) === 2 ? '立即修改' : '立即设置'}
                        </a>
                        <div className={styles.left_box}>
                            <span className={styles.pwdIcon} />
                            <span className={styles.fz16}>
                                {/* {Number(user_status) === 0 || Number(user_status) === 2 ? '修改密码' : '设置设置'} */}
                                设置密码
                            </span>
                            <p className={styles.itemTip}>定期修改密码，可提高您的账户安全</p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        return (
            <div className={styles.content}>
                <div className={styles.i_content}>
                    {lvBar}
                    {Items}
                </div>
            </div>
        );
    }
}

export default errorBoundary(Content);
