import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Footer extends React.PureComponent {
    render() {
        return (
            <div className={styles.footer}>
                <p className={styles.p1}>凤凰新媒体&nbsp;版权所有</p>
                <p className={styles.p2}>
                    Copyright © <script>document.write(new Date().getFullYear());</script>2020 Phoenix New Media Limited
                    All Rights Reserved.
                </p>
            </div>
        );
    }
}

export default errorBoundary(Footer);
