/**
 * 格式化search参数
 * @param {*} search location.search
 */
export const formatSearchParams = search => {
    const _search = search.split('?')[1];

    const res = {};

    _search.split('&').forEach(item => {
        const itemParams = item.split('=');

        res[itemParams[0]] = itemParams[1];
    });

    return res;
};
