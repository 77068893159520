import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

import { cookie } from '@ifeng-fe/ui_base';

import { cacheUserInfo, logoutPost } from '../../services/api';

class Nav extends React.PureComponent {
    static propTypes = {
        // userInfo: PropTypes.object,
        cur: PropTypes.number,
    };

    static defaultProps = {
        cur: 0,
    };

    state = {
        urls: [
            {
                url: '/my/info',
                txt: '基本设置',
            },
            {
                url: '/security',
                txt: '安全设置',
            },
        ],
    };

    componentDidMount = async () => {
        const userInfo = cookie.get('sid');

        if (!userInfo) {
            location.href = '/login';
        }
    };

    handleLogout = () => {
        logoutPost().then(res => {
            cookie.del('sid', '/', '.ifeng');
            cookie.del('IF_USER', '/', '.ifeng');
            cookie.del('IF_REAL', '/', '.ifeng');
            cookie.del('IF_TIME', '/', '.ifeng');

            location.href = '/login';
        });
    };

    render() {
        const { cur } = this.props;
        const { urls } = this.state;

        return (
            <div className={styles.nav}>
                <div className={styles.innerBox}>
                    <h1 className={styles.logo}>凤凰网</h1>
                    <div className={styles.navBtn}>
                        {urls.map((itme, index) => (
                            <a href={itme.url} className={cur === index ? styles.cur : ''} key={itme.url}>
                                {itme.txt}
                            </a>
                        ))}
                        <a className={styles.logout} onClick={this.handleLogout}>
                            注销
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Nav);
